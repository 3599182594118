import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from "./actionTypes";
//profile and reset password actions
export const editProfile = (user) => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  };
};

export const changePassword = (data, history) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { data, history },
  };
};

export const changePasswordSuccess = (user) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: user,
  };
};

export const changePasswordFail = (error) => {
  return { type: CHANGE_PASSWORD_FAIL, payload: error };
};

export const profileSuccess = (msg) => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  };
};

export const profileError = (error) => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
};

export const resetProfileFlag = (error) => {
  return {
    type: RESET_PROFILE_FLAG,
  };
};

/* GRADE */
export const GET_LEADGROUPS = "GET_LEADGROUPS";
export const GET_LEADGROUPS_SUCCESS = "GET_LEADGROUPS_SUCCESS";
export const GET_LEADGROUPS_FAIL = "GET_LEADGROUPS_FAIL";

/**
 * add GRADE
 */
export const ADD_NEW_LEADGROUPS= "ADD_NEW_LEADGROUPS";
export const ADD_LEADGROUPS_SUCCESS = "ADD_LEADGROUPS_SUCCESS";
export const ADD_LEADGROUPS_FAIL = "ADD_LEADGROUPS_FAIL";

/**
 * Edit GRADE
 */
export const UPDATE_LEADGROUPS = "UPDATE_LEADGROUPS";
export const UPDATE_LEADGROUPS_SUCCESS = "UPDATE_LEADGROUPS_SUCCESS";
export const UPDATE_LEADGROUPS_FAIL = "UPDATE_LEADGROUPS_FAIL";

/**
 * Delete GRADE
 */
export const DELETE_LEADGROUPS = "DELETE_LEADGROUPS";
export const DELETE_LEADGROUPS_SUCCESS = "DELETE_LEADGROUPS_SUCCESS";
export const DELETE_LEADGROUPS_FAIL = "DELETE_LEADGROUPS_FAIL";

import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
// Login Redux States
import { CHANGE_PASSWORD } from "./actionTypes";
import { changePasswordSuccess, changePasswordFail } from "./actions";
import { postChangePassword } from "../../../network-requests/profile";
import { changePreloader } from "../../actions";
import store from "../../../store";
import { logoutUser } from "../../../store/actions";

function* resetPassword({ payload: { data } }) {
  try {
    yield put(changePreloader(true));
    const response = yield call(postChangePassword, {
      old_password: data.old_password,
      new_password: data.new_password,
      confirm_password: data.confirm_password,
    });
    console.log("response", response);

    toast.success("Password changed successfully, please login again");
    yield put(
      changePasswordSuccess("Password changed successfully, please login again")
    );

    store.dispatch(logoutUser());
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong");
    yield put(
      changePasswordFail(
        error?.response?.data?.message || "Some thing went wrong"
      )
    );
  } finally {
    yield put(changePreloader(false));
  }
}

function* ProfileSaga() {
  yield takeEvery(CHANGE_PASSWORD, resetPassword);
}

export default ProfileSaga;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import "./wdyr";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import store from "./store";
import { ErrorBoundary } from "react-error-boundary";
import GenericError from "./pages/Utility/GenericError";
import NoInternet from "./components/Common/NoInternet";

function ErrorFallback({ error, resetErrorBoundary }) {
  return <GenericError errorValue={error.message} />;
}
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <React.Fragment>
      <NoInternet />
      <BrowserRouter>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={(e) => {
            console.log("error", e);
          }}
        >
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);

import { call, put, takeEvery } from "redux-saga/effects";

// employee Redux States
import {
  GET_EMPLOYEES,
  ADD_NEW_EMPLOYEE,
  UPDATE_EMPLOYEE,
  DELETE_EMPLOYEE,
} from "./actionTypes";

import {
  getEmployeesSuccess,
  getEmployeesFail,
  addEmployeeFail,
  addEmployeeSuccess,
  updateEmployeeSuccess,
  updateEmployeeFail,
  deleteEmployeeSuccess,
  deleteEmployeeFail,
} from "./actions";
import { changePreloader } from "../actions";
import { toast } from "react-toastify";
//Include Both Helper File with needed methods
import {
  getEmployees,
  addEmployee,
  deleteEmployee,
  updateEmployee,
} from "../../network-requests/employees";

function* fetchEmployees({ payload: params }) {
  try {
    yield put(changePreloader(true));
    const response = yield call(getEmployees,params);
    yield put(getEmployeesSuccess(response));
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.detail || "Something went wrong");
    yield put(getEmployeesFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateEmployees({
  payload: { employee, offset, limit, validation, onSetOff },
}) {
  try {
    yield put(changePreloader(true));

    const response = yield call(updateEmployee, employee);
    toast.success("Employee updated successfully");
    // yield put(updateEmployeeSuccess(employee));
    validation.resetForm();
    onSetOff();
    const res = yield call(getEmployees, { offset, limit });
    yield put(getEmployeesSuccess(res));
  } catch (error) {
    toast.error(error?.response.data.message || "Something went wrong");
    yield put(updateEmployeeFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteEmployee({
  payload: { employee, offset, limit, setOffset },
}) {
  try {
    yield put(changePreloader(true));
    yield call(deleteEmployee, employee);
    toast.success("Employee deleted successfully");
    const res = yield call(getEmployees, { offset, limit });
    yield put(getEmployeesSuccess(res));
    setOffset(offset);
  } catch (error) {
    toast.error(error?.response.data.message || "Something went wrong");
    yield put(deleteEmployeeFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onAddNewEmployees({
  payload: { employee, offset, limit, validation, toggle },
}) {
  try {
    yield put(changePreloader(true));
    yield call(addEmployee, employee);
    toast.success("Employee added successfully");
    validation.resetForm();
    toggle();
    yield put(changePreloader(true));
    const res = yield call(getEmployees, { offset: 0, limit });
    yield put(getEmployeesSuccess(res));
  } catch (error) {
    toast.error(error?.response.data.message || "Something went wrong");
    yield put(addEmployeeFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* employeesSaga() {
  yield takeEvery(GET_EMPLOYEES, fetchEmployees);
  yield takeEvery(ADD_NEW_EMPLOYEE, onAddNewEmployees);
  yield takeEvery(UPDATE_EMPLOYEE, onUpdateEmployees);
  yield takeEvery(DELETE_EMPLOYEE, onDeleteEmployee);
}

export default employeesSaga;

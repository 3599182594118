import HttpService from "../../utils/HttpService";

export const getProductsList = async (offset, limit, otherParams) => {
  // console.log("token in api", token);
  const axiosApi = new HttpService();
  const { data } =
    offset === "all"
      ? await axiosApi.get("product")
      : await axiosApi.get("product", {
          params: {
            offset,
            limit,
            ...otherParams,
          },
        });
  return data?.data;
};

export const addNewProduct = async (product) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.post("product", product);
  return data;
};

export const deleteProduct = async (product) => {
  const Mul = product.map((pro) => pro.id);

  // console.log("user",user)
  const axiosApi = new HttpService();
  const { data } = await axiosApi.delete("product?id=" + Mul);
  return data?.data;
};

export const updateProduct = async (product) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.patch("product", product);
  return data?.data;
};

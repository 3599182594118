import { call, put, takeEvery } from "redux-saga/effects";

// User Redux States
import {
  GET_USERS,
  ADD_NEW_USER,
  UPDATE_USER,
  DELETE_USER,
} from "./actionTypes";

import {
  getUsersSuccess,
  getUsersFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getUsers,
} from "./actions";
import { changePreloader } from "../actions";
import { ToastContainer, toast } from "react-toastify";
//Include Both Helper File with needed methods
import {
  getUsersList,
  addNewUser,
  deleteUser,
  updateUser,
} from "../../network-requests/users";

function* fetchUsers({ payload: params }) {
  try {
    yield put(changePreloader(true));
    // debugger
    const response = yield call(getUsersList, params);
    // console.log("response", responsedd);
    yield put(getUsersSuccess(response));
  } catch (error) {
    toast.error(error.response?.data?.detail || "Something went wrong");
    yield put(getUsersFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateUsers({
  payload: { user, offset, limit, validation, onSetOff },
}) {
  try {
    yield put(changePreloader(true));

    yield call(updateUser, user);
    toast.success("User Updateded successfully");
    // yield put(updateUserSuccess(user));
    validation.resetForm();
    onSetOff();
    const res = yield call(getUsersList, offset, limit);
    yield put(getUsersSuccess(res));
  } catch (error) {
    yield put(updateUserFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteUser({ payload: { user, offset, limit, setOffset } }) {
  try {
    yield put(changePreloader(true));
    yield call(deleteUser, user);
    toast.success("User deleted successfully");
    // yield put(deleteUserSuccess(user));

    const res = yield call(getUsersList, offset, limit);
    yield put(getUsersSuccess(res));
    setOffset(offset);
  } catch (error) {
    toast.error(error?.message || "Something went wrong");
    yield put(deleteUserFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onAddNewUsers({
  payload: { user, offset, limit, validation, toggle },
}) {
  try {
    yield put(changePreloader(true));
    yield call(addNewUser, user);
    toast.success("User added successfully");
    validation.resetForm();
    toggle();
    //  history.push("/users");
    // yield put(addUserSuccess(user));

    const res = yield call(getUsersList, 0, limit);

    // console.log("response", responsedd);
    yield put(getUsersSuccess(res));
  } catch (error) {
    toast.error(error.response?.data?.message || "Something went wrong");
    yield put(addUserFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers);
  yield takeEvery(ADD_NEW_USER, onAddNewUsers);
  yield takeEvery(UPDATE_USER, onUpdateUsers);
  yield takeEvery(DELETE_USER, onDeleteUser);
}

export default usersSaga;

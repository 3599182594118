import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import Loader from "../../components/Common/Loader";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      return (
        <Suspense fallback={<Loader />}>
          <Layout>
            <Component {...props} />
          </Layout>
        </Suspense>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;

import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { changePreloader, getUsersSuccess } from "../actions";
// notification Redux States
import {
  GET_NOTIFICATIONS,
  ADD_NEW_NOTIFICATION,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
} from "./actionTypes";

import {
  getNotificationsSuccess,
  getNotificationsFail,
  addNotificationFail,
  updateNotificationFail,
  deleteNotificationFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getNotifications,
  addNotification,
  updateNotification,
  deleteNotification,
} from "../../network-requests/notifications";
import { getUsersList } from "../../network-requests/users";

function* fetchNotifications({ payload: params }) {
  try {
    yield put(changePreloader(true));
    const response = yield call(getNotifications, params);
    console.log("notification", response);
    yield put(getNotificationsSuccess(response));
  } catch (error) {
    toast.error(error.response?.data?.message || "Something went wrong");
    yield put(getNotificationsFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateNotifications({
  payload: { notification, offset, limit, validation, onSetOff },
}) {
  try {
    yield put(changePreloader(true));
    yield call(updateNotification, notification);
    toast.success("Notification Updated Successfully");
    validation.resetForm();
    onSetOff();
    const response = yield call(getNotifications, { offset, limit });
    yield put(getNotificationsSuccess(response));

    // yield put(updatenotificationSuccess(notification));
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong");
    yield put(updateNotificationFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteNotification({
  payload: { notification, offset, limit, setOffset },
}) {
  try {
    yield put(changePreloader(true));
    yield call(deleteNotification, notification);
    toast.success("Notification Deleted Successfully");
    // yield put(deleteNotificationSuccess(notification));
    const response = yield call(getNotifications, { offset, limit });
    yield put(getNotificationsSuccess(response));
    setOffset(offset);
  } catch (error) {
    toast.error(error.response?.data?.message || "Something went wrong");
    yield put(deleteNotificationFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onAddNewNotifications({
  payload: { notification, offset, limit, validation, toggle2 },
}) {
  try {
    yield put(changePreloader(true));

    yield call(addNotification, notification);
    toast.success("Message Sent Successfully");
    window.location.reload();

    const response = yield call(getUsersList, { offset: 0, limit });
    yield put(getUsersSuccess(response));
    // yield put(addnotificationSuccess(notification));
    validation.resetForm();
    toggle2();
  } catch (error) {
    console.log("errorrrrr", error);
    toast.error(error?.response.data.message || "Something went wrong");
    yield put(addNotificationFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* notificationsSaga() {
  yield takeEvery(GET_NOTIFICATIONS, fetchNotifications);
  yield takeEvery(ADD_NEW_NOTIFICATION, onAddNewNotifications);
  yield takeEvery(UPDATE_NOTIFICATION, onUpdateNotifications);
  yield takeEvery(DELETE_NOTIFICATION, onDeleteNotification);
}

export default notificationsSaga;

/* departments */
export const GET_IMPORTMESSAGES = "GET_IMPORTMESSAGES";
export const GET_IMPORTMESSAGES_SUCCESS = "GET_IMPORTMESSAGES_SUCCESS";
export const GET_IMPORTMESSAGES_FAIL = "GET_IMPORTMESSAGES_FAIL";

/**
 * add department
 */
export const ADD_NEW_IMPORTMESSAGE = "ADD_NEW_IMPORTMESSAGE";
export const ADD_IMPORTMESSAGE_SUCCESS = "ADD_IMPORTMESSAGE_SUCCESS";
export const ADD_IMPORTMESSAGE_FAIL = "ADD_IMPORTMESSAGE_FAIL";


import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";
import { postForgetPassword } from "../../../network-requests/auth";
import { changePreloader } from "../../actions";

function* forgetUser({ payload: { user, history } }) {
  try {
    yield put(changePreloader(true));
    yield call(postForgetPassword, {
      email: user.email.toLowerCase(),
    });
    history.push("/reset-password");
    toast.success("OTP Sent Successfully");
    yield put(
      userForgetPasswordSuccess(
        "OTP Sent Successfully"
      )
    );
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong");
    yield put(
      userForgetPasswordError(
        error?.response?.data?.message || "Some thing went wrong"
      )
    );
  } finally {
    yield put(changePreloader(false));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;

// Employee
import HttpService from "../../utils/HttpService";

export const getImportMessages = async (param) => {
  // console.log("offset: ", offset);
  const axiosApi = new HttpService();
  const { data } =
    param?.offset === "all"
      ? await axiosApi.get("import-leads")
      : await axiosApi.get("import-leads", {
          params: param,
        });
  return data?.data;
};

export const addImportMessage = async (importMessage) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.post("import-leads", importMessage);
  return data?.data;
};

// Employee
import HttpService from "../../utils/HttpService";

export const getEmployees = async (param) => {
  // console.log("offset: ", offset);
  const axiosApi = new HttpService();
  const { data } =
    param?.offset === "all"
      ? await axiosApi.get("employee")
      : await axiosApi.get("employee", {
          params: param,
        });
  return data?.data;
};

export const addEmployee = async (employee) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.post("employee", employee);
  return data?.data;
};
export const deleteEmployee = async (employee) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.delete("employee?id=" + [0, ...employee]);
  return data?.data;
};
export const updateEmployee = async (employee) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.patch("employee", employee);
  return data?.data;
};

import {
  GET_IMPORTMESSAGES,
  GET_IMPORTMESSAGES_FAIL,
  GET_IMPORTMESSAGES_SUCCESS,
  ADD_NEW_IMPORTMESSAGE,
  ADD_IMPORTMESSAGE_SUCCESS,
  ADD_IMPORTMESSAGE_FAIL,
 
} from "./actionTypes";

export const getImportMessages = (params) => ({
  type: GET_IMPORTMESSAGES,
  payload: params,
});

export const getImportMessagesSuccess = (notifications) => ({
  type: GET_IMPORTMESSAGES_SUCCESS,
  payload: notifications,
});
export const addNewImportMessage = (
  notification,
  offset,
  limit,
  validation,
  toggle2
) => ({
  type: ADD_NEW_IMPORTMESSAGE,
  payload: { notification, offset, limit, validation, toggle2 },
});

export const addImportMessageSuccess = (notification) => ({
  type: ADD_IMPORTMESSAGE_SUCCESS,
  payload: notification,
});

export const addImportMessageFail = (error) => ({
  type: ADD_IMPORTMESSAGE_FAIL,
  payload: error,
});

export const getImportMessagesFail = (error) => ({
  type: GET_IMPORTMESSAGES_FAIL,
  payload: error,
});



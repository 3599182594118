import {
  GET_EMPLOYEES,
  GET_EMPLOYEES_FAIL,
  GET_EMPLOYEES_SUCCESS,
  ADD_NEW_EMPLOYEE,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAIL,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAIL,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAIL,
} from "./actionTypes";

export const getEmployees = (params) => ({
  type: GET_EMPLOYEES,
  payload: params,
});

export const getEmployeesSuccess = (employees) => ({
  type: GET_EMPLOYEES_SUCCESS,
  payload: employees,
});

export const addNewEmployee = (
  employee,
  offset,
  limit,
  validation,
  toggle
) => ({
  type: ADD_NEW_EMPLOYEE,
  payload: { employee, offset, limit, validation, toggle },
});

export const addEmployeeSuccess = (employee) => ({
  type: ADD_EMPLOYEE_SUCCESS,
  payload: employee,
});

export const addEmployeeFail = (error) => ({
  type: ADD_EMPLOYEE_FAIL,
  payload: error,
});

export const getEmployeesFail = (error) => ({
  type: GET_EMPLOYEES_FAIL,
  payload: error,
});

export const updateEmployee = (
  employee,
  offset,
  limit,
  validation,
  onSetOff
) => ({
  type: UPDATE_EMPLOYEE,
  payload: { employee, offset, limit, validation, onSetOff },
});

export const updateEmployeeSuccess = (employee) => ({
  type: UPDATE_EMPLOYEE_SUCCESS,
  payload: employee,
});

export const updateEmployeeFail = (error) => ({
  type: UPDATE_EMPLOYEE_FAIL,
  payload: error,
});

export const deleteEmployee = (employee, offset, limit, setOffset) => ({
  type: DELETE_EMPLOYEE,
  payload: { employee, offset, limit, setOffset },
});

export const deleteEmployeeSuccess = (employee, offset, limit) => ({
  type: DELETE_EMPLOYEE_SUCCESS,
  payload: { employee, offset, limit },
});

export const deleteEmployeeFail = (error) => ({
  type: DELETE_EMPLOYEE_FAIL,
  payload: error,
});

import {
  GET_LEADGROUPS,
  GET_LEADGROUPS_FAIL,
  GET_LEADGROUPS_SUCCESS,
  ADD_NEW_LEADGROUPS,
  ADD_LEADGROUPS_SUCCESS,
  ADD_LEADGROUPS_FAIL,
  UPDATE_LEADGROUPS,
  UPDATE_LEADGROUPS_SUCCESS,
  UPDATE_LEADGROUPS_FAIL,
  DELETE_LEADGROUPS,
  DELETE_LEADGROUPS_SUCCESS,
  DELETE_LEADGROUPS_FAIL,
} from "./actionTypes";

export const getLeadGroups = (params) => ({
  type: GET_LEADGROUPS,
  payload: params,
});

export const getLeadGroupsSuccess = (leadgroups) => ({
  type: GET_LEADGROUPS_SUCCESS,
  payload: leadgroups,
});

export const addNewLeadGroups = (
  newLeadGroup,
  offset,
  limit,
  toggle2,
  validation,
  onSetOff
) => ({
  type: ADD_NEW_LEADGROUPS,
  payload: {   newLeadGroup,
    offset,
    limit,
    toggle2,
    validation,
    onSetOff },
});

export const addLeadGroupsSuccess = (leadgroups) => ({
  type: ADD_LEADGROUPS_SUCCESS,
  payload: leadgroups,
});

export const addLeadGroupsFail = (error) => ({
  type: ADD_LEADGROUPS_FAIL,
  payload: error,
});

export const getLeadGroupsFail = (error) => ({
  type: GET_LEADGROUPS_FAIL,
  payload: error,
});

export const updateLeadGroups = (
  leadgroups,
  offset,
  limit,
  validation,
  toggle
) => ({
  type: UPDATE_LEADGROUPS,
  payload: { leadgroups, offset, limit, validation, toggle },
});

export const updateLeadGroupsSuccess = (leadgroups) => ({
  type: UPDATE_LEADGROUPS_SUCCESS,
  payload: leadgroups,
});

export const updateLeadGroupsFail = (error) => ({
  type: UPDATE_LEADGROUPS_FAIL,
  payload: error,
});

export const deleteLeadGroups = (leadgroups, offset, limit, setOffset) => ({
  type: DELETE_LEADGROUPS,
  payload: { leadgroups, offset, limit, setOffset },
});

export const deleteLeadGroupsSuccess = (leadgroups) => ({
  type: DELETE_LEADGROUPS_SUCCESS,
  payload: leadgroups,
});

export const deleteLeadGroupsFail = (error) => ({
  type: DELETE_LEADGROUPS_FAIL,
  payload: error,
});

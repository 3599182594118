import {
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_SUCCESS,
  ADD_NOTIFICATION_SUCCESS,
  ADD_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  notifications: [],
  count: 0,
  error: {},
  loading: true,
};

const notifications = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload?.data,
        count: action.payload?.count,
        loading: false,
      };

    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        products: [action.payload, ...state.products],
      };

    case ADD_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        products: state.products.map((products) =>
          products.id.toString() === action.payload.id.toString()
            ? { products, ...action.payload }
            : products
        ),
      };

    case UPDATE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          (products) => products.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default notifications;

import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

const Dashboard = lazy(() => import("../pages/Dashboard/index"));
const LeadGroup = lazy(() => import("../pages/leadGroup"));


// Department

//Designation

//Plants

// Authentication related pages
const Login = lazy(() => import("../pages/Authentication/Login"));
const Logout = lazy(() => import("../pages/Authentication/Logout"));
const ForgetPwd = lazy(() => import("../pages/Authentication/ForgetPassword"));
const userProfile = lazy(() => import("../pages/Profile"));

// Company Setup

// const AreaView = lazy(() => import("../pages/CompanySetup/AreaView"));

//Not Found
const NotFound = lazy(() => import("../pages/Utility/Error404"));
const Users = lazy(() => import("../pages/UserManagement/User"));
const Employees = lazy(() => import("../pages/UserManagement/Employees"));
const AddEmployeeForm = lazy(() =>
  import("../pages/UserManagement/Employees/EmployeeForm")
);

// Roles and Permissions

//Products
const Products = lazy(() => import("../pages/Products/index"));

//Bulk Messages
const BulkMessages = lazy(() => import("../pages/BulkMessages/index"));

//Chats
const Chats = lazy(() => import("../pages/Chats/index"));

//Brands

//WareHosue
//Notifications
const Notifications = lazy(() => import("../pages/Notifications"));

//WareHosue

//Reset PAssword Password
const ResetPassword = lazy(() =>
  import("../pages/Authentication/ResetPassword")
);

///Hierarchry

//Channels
//Landing Page

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: userProfile },

  { path: "/users", component: Users },
  { path: "/employees", component: Employees },
  { path: "/add-employee", component: AddEmployeeForm },
  { path: "/products", component: Products },
  { path: "/leadgroups", component: LeadGroup },
  { path: "/bulk-messages", component: BulkMessages},
  { path: "/chats", component: Chats },

  ///Hierarchry 
  // { path: "/hierarchry", component: Hierarchry },

  //Plants
  //Modules

  //channels

  { path: "/notifications", component: Notifications },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  { path: "*", component: NotFound },
];

const authRoutes = [
  //authencation page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password", component: ResetPassword },
];

export { userRoutes, authRoutes };

import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  changeSuccess: "",
};
//profile function with reset password
const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null };
      break;
    case CHANGE_PASSWORD_SUCCESS:
      state = { ...state, changeSuccess: "Passwords changed successfully" };
      break;
    case CHANGE_PASSWORD_FAIL:
      state = { ...state, changeSuccess: null, error: action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;

import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { postLogin } from "../../../network-requests/auth";
import { toast } from "react-toastify";
import { changePreloader } from "../../actions";

//login user generator function
function* loginUser({ payload: { user, history, from } }) {
  try {
    yield put(changePreloader(true));
    const response = yield call(postLogin, {
      username: user.email.toLowerCase(),
      password: user.password,
    });

    toast.success("Logedin Successfully");
    window.localStorage.setItem("authUser", response?.data?.token);
    window.localStorage.setItem("token", response?.data?.token);
    yield put(loginSuccess(response?.data));
    history.replace(from);
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong");
    yield put(apiError(error?.response?.data));
  } finally {
    yield put(changePreloader(false));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    window.localStorage.clear();
    yield put(logoutUserSuccess());
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;

import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";

function InternetStatus() {
  // state to store the Internet connection status
  const [online, setOnline] = useState(navigator.onLine);
  // effect to run when the online state changes
  useEffect(() => {
    // add event listeners to detect when the Internet connection is lost or restored
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // remove the event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [online]); // only run the effect when the online state changes

  // function to handle when the Internet connection is lost
  function handleOffline() {
    setOnline(false);
  }

  // function to handle when the Internet connection is restored
  function handleOnline() {
    setOnline(true);
  }

  return (
    <div>
      {!online && (
        <Modal isOpen={!online} centered>
          <div>
            <h1 className="text-center">No Internet</h1>
            <br />
            <p className="text-center">
              Please check your Internet connection and try again.
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default InternetStatus;

import { call, put, takeEvery } from "redux-saga/effects";

// User Redux States
import {
  GET_PRODUCTS,
  ADD_NEW_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
} from "./actionTypes";

import {
  getProductsSuccess,
  getProductsFail,
  addProductFail,
  addProductSuccess,
  updateProductSuccess,
  updateProductFail,
  deleteProductSuccess,
  deleteProductFail,
  getProducts,
} from "./actions";
import { changePreloader } from "../actions";
import { ToastContainer, toast } from "react-toastify";
//Include Both Helper File with needed methods
import {
  getProductsList,
  addNewProduct,
  deleteProduct,
  updateProduct,
} from "../../network-requests/products";

function* fetchProducts({ payload: { offset, limit, otherParams } }) {
  try {
    yield put(changePreloader(true));
    const response = yield call(getProductsList, offset, limit, otherParams);
    // console.log("response", responsedd);
    yield put(getProductsSuccess(response));
  } catch (error) {
    toast.error(error.response?.data?.detail || "Something went wrong");
    yield put(getProductsFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateProducts({
  payload: { product, offset, limit, validation,onSetOff },
}) {
  try {
    yield put(changePreloader(true));

    yield call(updateProduct, product);
    toast.success("Product Updated successfully");
    // yield put(updateUserSuccess(user));
    validation.resetForm();
    onSetOff();
    const res = yield call(getProductsList, offset, limit);
    yield put(getProductsSuccess(res));
  } catch (error) {
    yield put(updateProductFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteProduct({ payload: { product, offset, limit,setOffset } }) {
  try {
    yield put(changePreloader(true));
    yield call(deleteProduct, product);
    toast.success("Product deleted successfully");
    // yield put(deleteUserSuccess(user));

    const res = yield call(getProductsList, offset, limit);
    yield put(getProductsSuccess(res));
    setOffset(offset);
  } catch (error) {
    toast.error(error?.message || "Something went wrong");
    yield put(deleteProductFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onAddNewProducts({
  payload: { product, offset, limit, validation, toggle },
}) {
  try {
    yield put(changePreloader(true));
    yield call(addNewProduct, product);
    toast.success("Product added successfully");
    validation.resetForm();
    toggle();
    //  history.push("/users");
    // yield put(addUserSuccess(user));

    const res = yield call(getProductsList, 0, limit);

    // console.log("response", responsedd);
    yield put(getProductsSuccess(res));
  } catch (error) {
    toast.error(error.response?.data?.message || "Something went wrong");
    yield put(addProductFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* productsSaga() {
  yield takeEvery(GET_PRODUCTS, fetchProducts);
  yield takeEvery(ADD_NEW_PRODUCT, onAddNewProducts);
  yield takeEvery(UPDATE_PRODUCT, onUpdateProducts);
  yield takeEvery(DELETE_PRODUCT, onDeleteProduct);
}

export default productsSaga;

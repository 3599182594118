/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH";
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME";
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME";

//layout mode
export const CHANGE_LAYOUT_THEME = "CHANGE_LAYOUT_THEME";

//layout postion
export const CHANGE_LAYOUT_POSITION = "CHANGE_LAYOUT_POSITION";

// show sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR";
export const TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU";

/* RIGHT SIDEBAR */
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR";

// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER";

//Modules
export const SET_MODULE_NAME = "SET_MODULE_NAME";


import {
  GET_EMPLOYEES,
  GET_EMPLOYEES_FAIL,
  GET_EMPLOYEES_SUCCESS,
  ADD_NEW_EMPLOYEE,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAIL,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAIL,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  employees: [],
  error: {},
  count: 0,
  loading: true,
  offsetValue: 0,
};

const employees = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.payload?.data,
        count: action.payload.count,
        loading: false,
        offsetValue: 0,
      };

    case GET_EMPLOYEES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employees: [...state.employees, action.payload],
      };

    case ADD_EMPLOYEE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employees: state.employees.map((employee) =>
          employee.id.toString() === action.payload.id.toString()
            ? { employee, ...action.payload }
            : employee
        ),
      };

    case UPDATE_EMPLOYEE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employees: state.employees.filter(
          (employee) => employee.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_EMPLOYEE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default employees;

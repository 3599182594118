import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import ResetPassword from "./auth/resetpwd/reducer";

// Company Setup

// Department

//Designation

//Products
import productReducer from "./product/reducer";

//Brands

//locators Data
import users from "./users/reducer";
import leadgroupsReducer from "./leadGroups/reducers";

//Roles and permissions

//Channels
import employeesReducer from "./employees/reducer";

//wareHouse

//Notification
import notificationReducer from "./notifications/reducer";
import importMessageReducer from "./ImportMessage/reducer";
//Plants

//Moduels

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  ResetPassword,
  importMessageReducer,
  Profile,
  notificationReducer,
  productReducer,
  users,
  employeesReducer,
  leadgroupsReducer,
});

export default rootReducer;

import HttpService from "../../utils/HttpService";

export const getUsersList = async (param) => {
  debugger
  // console.log("token in api", token);
  const axiosApi = new HttpService();
  const { data } =
  param.offset === "all"
      ? await axiosApi.get("lead")
      : await axiosApi.get("lead", {
          params: param,
        });
  return data?.data;
};

export const addNewUser = async (user) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.post("lead", user);
  return data;
};

export const deleteUser = async (user) => {
  // console.log("user",user)
  const axiosApi = new HttpService();
  const { data } = await axiosApi.delete("lead?id=" + user);
  return data?.data;
};

export const updateUser = async (user) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.patch("lead", user);
  return data?.data;
};

import axios from "axios";
import { BASE_URL } from "../../constants/urls";

export const postLogin = async (data, config = {}) => {
  let response = await axios.post(
    `${BASE_URL}/login`,
    {
      ...data,
    },
    {
      headers: {
        "Content-Type": "application/json; charset=UTF-8,multipart/form-data",

        Accept: "application/json",
      },
    }
  );

  return response.data;
};

export const postForgetPassword = async (data, config = {}) => {
  let response = await axios.post(
    `${BASE_URL}/forget-password`,
    {
      ...data,
    },
    {
      headers: {
        "Content-Type": "application/json; charset=UTF-8,multipart/form-data",

        Accept: "application/json",
      },
    }
  );

  return response.data;
};

export const postResetPassword = async (data, config = {}) => {
  let response = await axios.post(
    `${BASE_URL}/verify_otp`,
    {
      ...data,
    },
    {
      headers: {
        "Content-Type": "application/json; charset=UTF-8,multipart/form-data",

        Accept: "application/json",
      },
    }
  );

  return response.data;
};

import PropTypes from "prop-types";
import React, { lazy } from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";
import { Alert } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";


// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";

// const Authmiddleware = lazy(() => import("./routes/middleware/Authmiddleware"));
// const VerticalLayout = lazy(() => import("./components/HorizontalLayout"));
// const HorizontalLayout = lazy(() => import("./components/VerticalLayout"));
// const NonAuthLayout = lazy(() => import("./components/NonAuthLayout"));
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

// import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
// fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = (props) => {
  const Lay = useSelector((state) => state.Layout.layoutType);
  function getLayout(Lay) {
    let layoutCls = VerticalLayout;
    switch (Lay) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout(Lay);
  const status = useSelector((state) => state.Login?.status);
  const { error } = useSelector((state) => ({
    error: state.Login.error,
  }));
  return (
    <React.Fragment>
      {/* {error?.message ? <Alert color="danger">{error?.message}</Alert> : null} */}

      <ToastContainer
        data-testid="app-component"
        position="top-right"
        autoClose={5000}
        draggable
      />
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              status={status}
              exact
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              status={status}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

// const mapStateToProps = (state) => {
//   return {
//     layout: state.Layout,
//   };
// };

export default App;

import React from "react";
import logo from "../../assets/images/LogoAtlas.jpg";
const Loader = () => {
  return (
    <>
      <div className="loading-wrapper-global">
        {" "}
        <img className="loading-logo" width={100} src={logo} />
      </div>
    </>
  );
};

export default Loader;

import { call, put, takeEvery } from "redux-saga/effects";

// employee Redux States
import { GET_IMPORTMESSAGES, ADD_NEW_IMPORTMESSAGE } from "./actionTypes";

import {
  getImportMessagesSuccess,
  getImportMessagesFail,
  addImportMessageFail,
  addImportMessageSuccess,
} from "./actions";
import { changePreloader, getUsers, getUsersSuccess } from "../actions";
import { toast } from "react-toastify";
//Include Both Helper File with needed methods
import {
  getImportMessages,
  addImportMessage,
} from "../../network-requests/importMessages";
import { getUsersList } from "../../network-requests/users";

function* fetchImportMessages({ payload: params }) {
  try {
    yield put(changePreloader(true));
    const response = yield call(getImportMessages, params);
    yield put(getImportMessagesSuccess(response));
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.detail || "Something went wrong");
    yield put(getImportMessagesFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onAddNewImportMessages({
  payload: { notification, offset, limit, validation, toggle },
}) {
  try {
    yield put(changePreloader(true));
    yield call(addImportMessage, notification);
    toast.success("File Imported successfully");

    const response = yield call(getUsersList, "all");
    // console.log("response", responsedd);
    yield put(getUsersSuccess(response));

    validation.resetForm();

    toggle();
  } catch (error) {
    toast.error(error?.response.data.message || "Something went wrong");
    yield put(addImportMessageFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* ImportMessagesSaga() {
  yield takeEvery(GET_IMPORTMESSAGES, fetchImportMessages);
  yield takeEvery(ADD_NEW_IMPORTMESSAGE, onAddNewImportMessages);
}

export default ImportMessagesSaga;

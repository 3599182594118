import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { changePreloader } from "../actions";

import {
  GET_LEADGROUPS,
  ADD_NEW_LEADGROUPS,
  UPDATE_LEADGROUPS,
  DELETE_LEADGROUPS,
} from "./actionTypes";

import {
  addLeadGroupsSuccess,
  addLeadGroupsFail,
  updateLeadGroupsSuccess,
  updateLeadGroupsFail,
  deleteLeadGroupsSuccess,
  deleteLeadGroupsFail,
  getLeadGroupsSuccess,
  getLeadGroupsFail,
} from "./actions";
import {
  addLeadGroups,
  getLeadGroups,
  updateLeadGroups,
  deleteLeadGroups,
} from "../../network-requests/leadgroups";
//Include Both Helper File with needed methods

function* fetchLeadGroups({ payload: params }) {
  try {
    yield put(changePreloader(true));
    const response = yield call(getLeadGroups, params);

    yield put(getLeadGroupsSuccess(response));
  } catch (error) {
    toast.error(error?.response?.data?.detail || "Something went wrong");
    yield put(getLeadGroupsFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onUpdateLeadGroups({
  payload: { leadgroups, offset, limit, validation, toggle },
}) {
  try {
    yield put(changePreloader(true));
    yield call(updateLeadGroups, leadgroups);
    toast.success("Lead Groups Updated Successfully");
    validation.resetForm();
    toggle();
    const response = yield call(getLeadGroups, { offset, limit });
    yield put(getLeadGroupsSuccess(response));

    // yield put(updateDesignationSuccess(designation));
  } catch (error) {
    toast.error(error?.response?.data.message || "Something went wrong");
    yield put(updateLeadGroupsFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onDeleteLeadGroups({
  payload: { leadgroups, offset, limit, setOffset },
}) {
  try {
    yield put(changePreloader(true));
    yield call(deleteLeadGroups, leadgroups);
    toast.success("Lead Groups Deleted Successfully");
    // yield put(deleteDesignationSuccess(designation));
    const response = yield call(getLeadGroups, { offset, limit });
    yield put(getLeadGroupsSuccess(response));
    setOffset(offset);
  } catch (error) {
    // console.log(error.response.data.message);
    toast.error(error.response?.data?.message || "Something went wrong");
    yield put(deleteLeadGroupsFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* onAddNewLeadGroups({
  payload: { newLeadGroup, offset, limit, validation, onSetOff, toggle2 },
}) {
  try {
    // debugger;
    yield put(changePreloader(true));
    yield call(addLeadGroups, newLeadGroup);
    toast.success("Lead Groups Added Successfully");
    const response = yield call(getLeadGroups, { offset: 0, limit });
    yield put(getLeadGroupsSuccess(response));
    // yield put(addDesignationSuccess(designation));
    toggle2();
    validation.resetForm();
  } catch (error) {
    console.log("errorrrrr", error);
    toast.error(error?.response.data.message || "Something went wrong");
    yield put(addLeadGroupsFail(error));
  } finally {
    yield put(changePreloader(false));
  }
}

function* leadgroupsSaga() {
  yield takeEvery(GET_LEADGROUPS, fetchLeadGroups);
  yield takeEvery(ADD_NEW_LEADGROUPS, onAddNewLeadGroups);
  yield takeEvery(UPDATE_LEADGROUPS, onUpdateLeadGroups);
  yield takeEvery(DELETE_LEADGROUPS, onDeleteLeadGroups);
}

export default leadgroupsSaga;

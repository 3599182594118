import {
    GET_PRODUCTS,
    GET_PRODUCTS_FAIL,
    GET_PRODUCTS_SUCCESS,
    ADD_NEW_PRODUCT,
    ADD_PRODUCT_SUCCESS,
    ADD_PRODUCT_FAIL,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    DELETE_PRODUCT,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
  } from "./actionTypes";
  
  const INIT_STATE = {
    products: [],
    error: {},
    count: 0,
    loading: true,
    offsetValue: 0,
  };
  
  const products = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_PRODUCTS_SUCCESS:
        return {
          ...state,
          products: action.payload?.data,
          count: action.payload.count,
          loading: false,
          offsetValue: 0,
        };
  
      case GET_PRODUCTS_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
  
      case ADD_PRODUCT_SUCCESS:
        return {
          ...state,
          products: [...state.products, action.payload],
        };
  
      case ADD_PRODUCT_FAIL:
        return {
          ...state,
          error: action.payload,
        };
  
      case UPDATE_PRODUCT_SUCCESS:
        return {
          ...state,
          products: state.users.map((product) =>
          product.id.toString() === action.payload.id.toString()
              ? { product, ...action.payload }
              : product
          ),
        };
  
      case UPDATE_PRODUCT_FAIL:
        return {
          ...state,
          error: action.payload,
        };
  
      case DELETE_PRODUCT_SUCCESS:
        return {
          ...state,
          products: state.users.filter(
            (product) => product.id.toString() !== action.payload.id.toString()
          ),
        };
  
      case DELETE_PRODUCT_FAIL:
        return {
          ...state,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default products;
  
// notification
import HttpService from "../../utils/HttpService";

export const getNotifications = async (param) => {
  const axiosApi = new HttpService();
  
  const { data } =
    param?.offset === "all"
      ? await axiosApi.get("whatsapp")
      : await axiosApi.get("whatsapp", {
          params: param,
        });
  return data?.data;
};

export const addNotification = async (notification) => {
  debugger
  const axiosApi = new HttpService();
  const { data } = await axiosApi.post("whatsapp", notification);
  return data?.data;
};
export const deleteNotification = async (notification) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.delete(
    "whatsapp?id=" + [0, ...notification]
  );
  return data?.data;
};
export const updateNotification = async (notification) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.patch("whatsapp", notification);
  return data?.data;
};

import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { toast } from "react-toastify";
// Login Redux States
import { RESET_PASSWORD } from "./actionTypes";
import { userResetPasswordSuccess, userResetPasswordError } from "./actions";
import { postResetPassword } from "../../../network-requests/auth";
import { changePreloader } from "../../actions";

function* resetPassword({ payload: { user, history } }) {
  try {
    yield put(changePreloader(true));
    yield call(postResetPassword, {
      otp: user.otp,
      new_password: user.new_password,
      confirm_password: user.confirm_password,
    });

    toast.success("Password changed successfully, please login again");
    yield put(
      userResetPasswordSuccess(
        "Password changed successfully, please login again"
      )
    );

    history.push("/login");
  } catch (error) {
    console.log("error", error);
    toast.error(error?.response?.data?.message || "Something went wrong");
    yield put(
      userResetPasswordError(
        error?.response?.data?.message || "Some thing went wrong"
      )
    );
  } finally {
    yield put(changePreloader(false));
  }
}

export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

function* resetPasswordSaga() {
  yield all([fork(watchUserPasswordReset)]);
}

export default resetPasswordSaga;

// Grade
import HttpService from "../../utils/HttpService";

export const getLeadGroups = async (param) => {
  const axiosApi = new HttpService();
  const { data } =
    param.offset === "all"
      ? await axiosApi.get("/lead_group")
      : await axiosApi.get("/lead_group", {
          params: param,
        });
  return data?.data;
};

export const addLeadGroups = async (leadgroup) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.post("/lead_group", leadgroup);
  return data?.data;
};
export const deleteLeadGroups = async (leadgroup) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.delete("/lead_group?id=" + leadgroup);
  return data?.data;
};
export const updateLeadGroups = async (leadgroup) => {
  const axiosApi = new HttpService();
  const { data } = await axiosApi.patch("/lead_group", leadgroup);
  return data?.data;
};

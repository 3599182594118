import {
    GET_PRODUCTS,
    GET_PRODUCTS_FAIL,
    GET_PRODUCTS_SUCCESS,
    ADD_NEW_PRODUCT,
    ADD_PRODUCT_SUCCESS,
    ADD_PRODUCT_FAIL,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    DELETE_PRODUCT,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
  } from "./actionTypes";
  
  export const getProducts = (offset, limit, otherParams) => ({
    type: GET_PRODUCTS,
    payload: { offset, limit, otherParams },
  });
  
  export const getProductsSuccess = (products) => ({
    type: GET_PRODUCTS_SUCCESS,
    payload: products,
  });
  
  export const addNewProduct = (product, offset, limit, validation, toggle) => ({
    type: ADD_NEW_PRODUCT,
    payload: { product, offset, limit, validation, toggle },
  });
  
  export const addProductSuccess = (product) => ({
    type: ADD_PRODUCT_SUCCESS,
    payload: product,
  });
  
  export const addProductFail = (error) => ({
    type: ADD_PRODUCT_FAIL,
    payload: error,
  });
  
  export const getProductsFail = (error) => ({
    type: GET_PRODUCTS_FAIL,
    payload: error,
  });
  
  
  export const updateProduct = (product, offset, limit, validation, onSetOff) => ({
    type: UPDATE_PRODUCT,
    payload: { product, offset, limit, validation,onSetOff  },
  });
  
  export const updateProductSuccess = (product) => ({
    type: UPDATE_PRODUCT_SUCCESS,
    payload: product,
  });
  
  export const updateProductFail = (error) => ({
    type: UPDATE_PRODUCT_FAIL,
    payload: error,
  });
  
  export const deleteProduct = (product, offset, limit, setOffset) => ({
    type: DELETE_PRODUCT,
    payload: { product, offset, limit, setOffset },
  });
  
  export const deleteProductSuccess = (product) => ({
    type: DELETE_PRODUCT_SUCCESS,
    payload: product,
  });
  
  export const deleteProductFail = (error) => ({
    type: DELETE_PRODUCT_FAIL,
    payload: error,
  });
  
import {
  GET_IMPORTMESSAGES_FAIL,
  GET_IMPORTMESSAGES_SUCCESS,
  ADD_IMPORTMESSAGE_SUCCESS,
  ADD_IMPORTMESSAGE_FAIL,
 
} from "./actionTypes";

const INIT_STATE = {
  importMessages: [],
  count: 0,
  error: {},
  loading: true,
};

const importMessages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_IMPORTMESSAGES_SUCCESS:
      return {
        ...state,
        importMessages: action.payload?.data,
        count: action.payload?.count,
        loading: false,
      };

    case GET_IMPORTMESSAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_IMPORTMESSAGE_SUCCESS:
      return {
        ...state,
        products: [action.payload, ...state.products],
      };

    case ADD_IMPORTMESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

   


    default:
      return state;
  }
};

export default importMessages;

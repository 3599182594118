import { all, fork } from "redux-saga/effects";

import AuthSaga from "./auth/login/saga";

import ForgetSaga from "./auth/forgetpwd/saga";

import ResetPasswordSaga from "./auth/resetpwd/saga";
import ProfileSaga from "./auth/profile/saga";

import LayoutSaga from "./layout/saga";

import usersSaga from "./users/saga";
import productsSaga from "./product/saga";

import employeesSaga from "./employees/saga";
import leadgroupsSaga from "./leadGroups/saga";
import importMessagesSaga from "./ImportMessage/saga";

import notificationsSaga from "./notifications/saga";
// import importMessageSaga from "./ImportMessage/saga";

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(ResetPasswordSaga),
    fork(LayoutSaga),
    fork(usersSaga),
    fork(employeesSaga),
    fork(notificationsSaga),
    fork(importMessagesSaga),
    
    fork(productsSaga),
    fork(leadgroupsSaga),
  ]);
}

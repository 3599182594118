import {
  GET_LEADGROUPS_FAIL,
  GET_LEADGROUPS_SUCCESS,
  ADD_LEADGROUPS_SUCCESS,
  ADD_LEADGROUPS_FAIL,
  UPDATE_LEADGROUPS_SUCCESS,
  UPDATE_LEADGROUPS_FAIL,
  DELETE_LEADGROUPS_SUCCESS,
  DELETE_LEADGROUPS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  leadgroups: [],
  counts: 0,
  error: {},
  loading: true,
};

const leadgroups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEADGROUPS_SUCCESS:
      return {
        ...state,
        leadgroups: action.payload?.data,
        counts: action.payload?.count,
        loading: false,
      };

    case GET_LEADGROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case ADD_LEADGROUPS_SUCCESS:
      return {
        ...state,
        leadgroups: [action.payload, ...state.leadgroups],
      };

    case ADD_LEADGROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_LEADGROUPS_SUCCESS:
      return {
        ...state,
        leadgroups: state.leadgroups.map((leadgroups) =>
          leadgroups.id.toString() === action.payload.id.toString()
            ? { leadgroups, ...action.payload }
            : leadgroups
        ),
      };

    case UPDATE_LEADGROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_LEADGROUPS_SUCCESS:
      return {
        ...state,
        leadgroups: state.leadgroups.filter(
          (leadgroups) =>
            leadgroups.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_LEADGROUPS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default leadgroups;
